var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        {
          staticClass: "text-center custom-row",
          attrs: { "align-v": "center" }
        },
        [
          _c(
            "b-col",
            {
              staticClass: "ml-auto p-3 bg-white mt-4 navbar-profile",
              attrs: { "align-self": "end", md: "3", xl: "2" }
            },
            [_c("MenuUser")],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", [
              _c("h3", { staticClass: "text-left title-page" }, [
                _vm._v(
                  _vm._s(_vm.$t("welcome.welcome")) +
                    ", " +
                    _vm._s(_vm.nameUser) +
                    ", " +
                    _vm._s(_vm.$t("welcome.please")) +
                    ":"
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        {},
        [
          _c(
            "b-col",
            { staticClass: "country-group", attrs: { cols: "4" } },
            [
              _c("h5", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.$t("welcome.country")))
              ]),
              _c("b-row", [
                _c(
                  "a",
                  {
                    staticClass: "card-country",
                    class: { active: _vm.isActive },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.country("MX")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/mexico-flag.svg")
                      }
                    }),
                    _c("p", [_vm._v("Mexico")])
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "card-country",
                    class: { active: !_vm.isActive },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.country("US")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../assets/images/eeuu-flag.svg") }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.$t("welcome.usa")))])
                  ]
                )
              ])
            ],
            1
          ),
          _c("b-col", { staticClass: "country-group" }, [
            _c(
              "div",
              { staticClass: "subcard__container" },
              [
                _c("h5", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.$t("welcome.email")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.getUserEmail,
                      expression: "getUserEmail"
                    }
                  ],
                  staticClass: "custom-input mt-4",
                  attrs: {
                    id: "findByEmail",
                    type: "email",
                    name: "email",
                    autocomplete: "on",
                    placeholder: this.$i18n.t("welcome.email"),
                    autofocus: ""
                  },
                  domProps: { value: _vm.getUserEmail },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getPatient("email")
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.getUserEmail = $event.target.value
                    }
                  }
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-blue-dark ml-auto my-4",
                    attrs: { disabled: _vm.getUserEmail === "" },
                    on: {
                      click: function($event) {
                        return _vm.getPatient("email")
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("welcome.search")))]),
                    !_vm.isSearchEmail
                      ? _c("b-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-right", "aria-hidden": "true" }
                        })
                      : _c("b-icon", {
                          staticClass: "icon",
                          attrs: {
                            icon: "arrow-clockwise",
                            animation: "spin",
                            "font-scale": "1"
                          }
                        })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "subcard__container" },
              [
                _c("h5", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.$t("welcome.nhc")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nhcNumber,
                      expression: "nhcNumber"
                    }
                  ],
                  staticClass: "custom-input mt-4",
                  attrs: {
                    id: "findByNHC",
                    type: "number",
                    name: "NHC",
                    autocomplete: "on",
                    placeholder: this.$i18n.t("welcome.nhc"),
                    autofocus: ""
                  },
                  domProps: { value: _vm.nhcNumber },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getPatient("nhc")
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.nhcNumber = $event.target.value
                    }
                  }
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-blue-dark ml-auto my-4",
                    attrs: { disabled: _vm.nhcNumber === "" },
                    on: {
                      click: function($event) {
                        return _vm.getPatient("nhc")
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("welcome.search")))]),
                    !_vm.isSearchNhc
                      ? _c("b-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-right", "aria-hidden": "true" }
                        })
                      : _c("b-icon", {
                          staticClass: "icon",
                          attrs: {
                            icon: "arrow-clockwise",
                            animation: "spin",
                            "font-scale": "1"
                          }
                        })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }