<template>
  <b-container>
    <b-row class="text-center custom-row" align-v="center">
      <b-col align-self="end" md="3" xl="2" class="ml-auto p-3 bg-white mt-4 navbar-profile">
        <MenuUser />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div>
          <h3 class="text-left title-page">{{ $t('welcome.welcome') }}, {{ nameUser }}, {{ $t('welcome.please') }}:</h3>
        </div>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col class="country-group" cols="4">
        <h5 class="text-left">{{ $t('welcome.country') }}</h5>
        <b-row>
          <a href="" class="card-country" :class="{ active: isActive }" @click.prevent="country('MX')">
            <img src="../assets/images/mexico-flag.svg" />
            <p>Mexico</p>
          </a>
          <a href="" class="card-country" :class="{ active: !isActive }" @click.prevent="country('US')">
            <img src="../assets/images/eeuu-flag.svg" />
            <p>{{ $t('welcome.usa') }}</p>
          </a>
        </b-row>
      </b-col>
      <b-col class="country-group">
        <div class="subcard__container">
          <h5 class="text-left">{{ $t('welcome.email') }}</h5>
          <input
            id="findByEmail"
            v-on:keyup.enter="getPatient('email')"
            type="email"
            name="email"
            class="custom-input mt-4"
            autocomplete="on"
            :placeholder="this.$i18n.t('welcome.email')"
            autofocus
            v-model="getUserEmail"
          />
          <b-button class="btn btn-blue-dark ml-auto my-4" @click="getPatient('email')" :disabled="getUserEmail === ''">
            <span>{{ $t('welcome.search') }}</span>
            <b-icon icon="arrow-right" class="icon" aria-hidden="true" v-if="!isSearchEmail"></b-icon>
            <b-icon icon="arrow-clockwise" class="icon" animation="spin" font-scale="1" v-else></b-icon>
          </b-button>
        </div>
        <div class="subcard__container">
          <h5 class="text-left">{{ $t('welcome.nhc') }}</h5>
          <input
            id="findByNHC"
            v-on:keyup.enter="getPatient('nhc')"
            type="number"
            name="NHC"
            class="custom-input mt-4"
            autocomplete="on"
            :placeholder="this.$i18n.t('welcome.nhc')"
            autofocus
            v-model="nhcNumber"
          />
          <b-button class="btn btn-blue-dark ml-auto my-4" @click="getPatient('nhc')" :disabled="nhcNumber === ''">
            <span>{{ $t('welcome.search') }}</span>
            <b-icon icon="arrow-right" class="icon" aria-hidden="true" v-if="!isSearchNhc"></b-icon>
            <b-icon icon="arrow-clockwise" class="icon" animation="spin" font-scale="1" v-else></b-icon>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { API_HUBSPOT, API_ROOT } from '../Constants/Api';
import MenuUser from '../components/MenuUser.vue';
import { sessionStoragePatient } from '../data/localStoragePatient';
import { getPatientAPI } from '../helpers/getPatientAPI';
import { defaultConfigRol, initialForm } from '../components/Admin/Rol/initialDataFormRol';
import { AlertForm, AlertFormLarge, ICON } from '../helpers/Alert';
import { ApiRequest } from '../helpers/ApiRequest';
export default {
  name: 'Welcome',
  components: {
    MenuUser,
  },
  data: () => ({
    imageUser: '',
    nameUser: '',
    nhcNumber: '',
    getUserEmail: '',
    isActive: false, //false activa US
    countrySelect: '',
    isSearchEmail: false,
    isSearchNhc: false,
    sucursalesUS: [],
    sucursalesMX: [],
    rolData: [],
    configRolByName: [],
    isSuperAdmin: false,
    isAdmin: false,
    sedesOrigen: null,
    sucursales: null
  }),
  async created() {
    try {
      let data = await ApiRequest.post(`${API_ROOT}roles/obtenerConfigRol`, {
        id: this.$store.state.idRol,
      });
      this.configRolByName = data.config;
      this.$store.dispatch('allConfigByRol', this.configRolByName);
      if (data.rol.toLowerCase().includes('super admin')) {
        this.isSuperAdmin = true;
      }
      if (data.rol.toLowerCase().includes('admin')) {
        this.$store.dispatch('saveConfigRol', data.config[0].vistas);
      }

      this.sucursales   = JSON.parse( localStorage.getItem('sucursales') )
      this.sedesOrigen  = JSON.parse( localStorage.getItem('sedes') )
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, err.statusText || this.$i18n.t('permissions.errorConfigRole'), ICON.ERROR);
    }
  },
  async mounted() {
    sessionStorage.clear()
    this.$store.dispatch('saveConfigRol', initialForm);
    sessionStorage.removeItem('Article');
    sessionStorage.removeItem('arrayMedicine');
    sessionStorage.removeItem('arregloArticulos');
    this.nameUser = this.$store.state.userResponse && this.$store.state.userResponse.name;
    this.countrySelect = sessionStorage.getItem('country') || 'US';
    this.countrySelect === 'US' ? (this.$i18n.locale = 'en') : (this.$i18n.locale = 'es');
    this.isActive = this.countrySelect === 'US' ? false : true;
    // await this.getSedes();
    // await this.getSucursales();
  },
  methods: {
    country(country) {
      sessionStorage.setItem('country', country);
      this.countrySelect = country;
      if (country === 'US') {
        this.isActive = false;
        this.$i18n.locale = 'en';
      } else {
        this.isActive = true;
        this.$i18n.locale = 'es';
      }
    },
    async getPatient(item) {
      await getPatientAPI(item, this.dataLocal, this);
    },
    async dataLocal(userInfo) {
      let sucursalOrigen;
      if (!sessionStorage.getItem('country')) sessionStorage.setItem('country', 'US');
      if (userInfo) {
        if (userInfo.sucursal_defecto) {
          let origen = '',
            destino = '';
          if (this.countrySelect === 'US') {
            let { sucursal_defecto_origen, sucursal_defecto_destino } = userInfo.sucursal_defecto;
            origen = sucursal_defecto_origen;
            destino = sucursal_defecto_destino;
          } else {
            let { sucursal_mexico, sucursal_defecto_destino } = userInfo.sucursal_defecto;
            origen = sucursal_mexico;
            destino = sucursal_defecto_destino;
          }
          if (!origen)
            return AlertFormLarge(
              'No se encuentra configurado la sede Origen para este paciente',
              'Por favor revisar esta propiedad en Hubspot',
              ICON.WARNING
            );

          sucursalOrigen   = this.sucursales.find((el) => el.name === origen);
          const findOrigen = this.sedesOrigen.find((el) => el.label === origen);

          sessionStorage.setItem(
            'sedeOrigen',
            JSON.stringify({
              name: sucursalOrigen.name,
              value: findOrigen.value,
              country: sucursalOrigen.country,
            })
          );
          sessionStorage.setItem('sedeDestinoKey', destino || '');
        }
        sessionStoragePatient(userInfo, this.countrySelect);
      }
      await this.getRolConfig(sucursalOrigen);
      await this.getConfigPrograms(userInfo.email);
      await this.getConfigBranch(sucursalOrigen, userInfo.email, userInfo.subtipo);
    },

    async getRolConfig(sucursalOrigen) {
      //dependiendo de estas 3 variables rol, pais y sucursal se obtiene la configuracion de vistas
      if (!this.isSuperAdmin) {
        let configRol = this.configRolByName.find(
          (el) => el.pais === this.countrySelect && el.sucursal.includes(sucursalOrigen.name)
        );
        configRol = configRol ? configRol.vistas : defaultConfigRol;
        this.$store.dispatch('saveConfigRol', configRol);
      }
    },
    async getConfigPrograms(email) {
      try {
        let data = await ApiRequest.post(`${API_ROOT}pipelines/programas-score/productos`, {
          correo: email,
          pais: this.countrySelect === 'MX' ? 1 : 2,
        });
        this.$store.dispatch('configPrograms', data.productos);
        this.$store.dispatch('configPDF', data.reglas_pdf);
      } catch (err) {
        if (err.status === 401) {
          this.$store.dispatch('allConfigBussiness', []);
          this.$store.dispatch('configPDF', null);
          AlertFormLarge(
            `${err.status}: ${err.data?.msg || this.$i18n.t('permissions.errorBranch')}`,
            JSON.stringify(err.data.detalle),
            ICON.WARNING
          );
          if (err.data?.msg.includes('No se encontró una configuración de sucursal - pipeline')) {
            this.$router.push({ name: 'History' });
          }
        } else {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      }
    },
    async getConfigBranch(sucursalOrigen, email, subtipo) {
      try {
        let data = await ApiRequest.post(`${API_ROOT}pipelines/configXsucursal`, {
          sucursal: sucursalOrigen.name,
          subtipo
        });
        this.$store.dispatch('allConfigBussiness', data.productos);

        AlertForm(this.$i18n.t('permissions.patientFound'), '', ICON.SUCCESS);
        this.$router.push({ name: 'History' });
      } catch (err) {
        if (err.status === 401) {
          this.$store.dispatch('allConfigBussiness', []);
          AlertFormLarge(
            `${err.status}: ${err.data?.msg || this.$i18n.t('permissions.errorBranch')}`,
            JSON.stringify(err.data.detalle),
            ICON.WARNING
          );
          if (err.data?.msg.includes('No se encontró una configuración de sucursal - pipeline')) {
            this.$router.push({ name: 'History' });
          }
        } else {
          AlertForm(`Error Code: ${err.status}`, err.statusText || this.$i18n.t('permissions.errorBranch'), ICON.ERROR);
        }
      }
    },

    async getSucursales() {
      try {
        let [ branchesUs, branchesMx ] = await Promise.all([
          ApiRequest.get(`${API_HUBSPOT}sucursalesUS`),
          ApiRequest.get(`${API_HUBSPOT}sucursalesMX`)
        ])
        
        this.sucursalesUS = branchesUs.sucursales.map((el) => ({ name: el, country: 'us' }));

        this.sucursalesMX = branchesMx.sucursales.map((el) => ({ name: el, country: 'mx' }));
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async getSedes() {
      try {
        const data = await ApiRequest.get(`${API_HUBSPOT}sedes`);
        let sedesOrigen = data.sedes.find((el) => el.prop_name === 'sede_origen');
        this.sedesOrigen = sedesOrigen.datos;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
@import '../assets/css/global.css';
.title-page {
  margin: 60px 0px;
  color: #707070;
}
.country-group {
  background: #fff;
  padding: 20px;
  margin-right: 30px;
}

.subcard__container {
  display: flex;
  flex-direction: column;
}
.navbar-profile {
  border-radius: 20px;
}

.btn-blue-dark .icon {
  align-self: center;
  margin-left: 10px;
}

.card-country {
  width: 130px;
  height: 130px;
  padding: 10px;
  margin: 10px;
}

.card-country img {
  width: 60px;
}
</style>